/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 纷析云（杭州）技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年12月21日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import * as Sentry from "@sentry/vue";
import store from "@/store";
import manba from "manba";

export default {
  install(app) {
    if (process.env.VUE_APP_SENTRY_DNS) {
      const config = {
        app,
        environment: process.env.NODE_ENV === "development" ? 'staging' : 'prod',
        enabled: process.env.NODE_ENV !== "development",
        dsn: process.env.VUE_APP_SENTRY_DNS,
        tracesSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        profilesSampleRate: 1.0,
        integrations: [new Sentry.Replay(), new Sentry.BrowserProfilingIntegration()],
      };
      if (store.state.User.id) {
        config.initialScope = {
          tags: {
            "buildDate": manba(process.env.VUE_APP_BUID_DATE).format('f'),
            "serverBuildDate": store.state.buildTime,
            "ver": process.env.VUE_APP_VERSION,
          },
          user: store.state.User,
        }

        Sentry.setUser({
          email: store.state.User.email || store.state.User.mobile,
          fullName: store.state.User.realName,
        });
        Sentry.init(config);
      }
    }
  }
}
