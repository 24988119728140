<template>
  <div class="login-frame">
    <div class="login-frame-title">登录
      <template v-if="login.openId">并绑定微信</template>
    </div>
    <Form class="login" ref="loginForm" :model="login" :rules="{}" :validOnChange="true">
      <FormItem :showLabel="false" required prop="mobile" label="账号">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-user"></i>
          </div>
          <Input v-model.trim="login.mobile" type="text" placeholder="请输入账号" class="h-42px"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" required prop="password" label="密码" class="my-16px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-lock"></i>
          </div>
          <Input v-model.trim="login.password" placeholder="请输入密码" type="password" @keyup.enter="submit"/>
        </div>
        <div class="text-right mt-16px">
          <span @click="$emit('changeView','ForgotPassword')" class="placeholder forgot-password text-hover">
            忘记密码
          </span>
        </div>
      </FormItem>
      <FormItem :showLabel="false" class="!pb-0px">
        <Button @click="submit" :loading="loading" color="primary" block>登 录</Button>
        <div class="text-center mt-32px" v-if="webConfig.register">
          还没账号，
          <span class="text-hover primary-color" @click="$emit('changeView','Registered')">注册账号</span>
          <template v-if="webConfig.wechatLogin">
            或
            <span class="text-hover primary-color" @click="$emit('changeView','WxLogin')">微信登录</span>
          </template>
        </div>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import {mapState} from "vuex";
import common from "@api/common";

export default {
  name: 'LoginForm',
  data() {
    return {
      login: {
        mobile: localStorage.getItem("login_mobile"),
        password: "",
        openId: null,
      },
      loading: false
    }
  },
  computed: {
    ...mapState(['webConfig'])
  },
  methods: {
    submit() {
      let validResult = this.$refs.loginForm.valid();
      if (validResult.result) {
        this.loading = true
        common.login(this.login).then(() => {
          localStorage.setItem("login_mobile", this.login.mobile);
          localStorage.removeItem("financial-openId");
          window.location.replace('/');
        }).catch(() => {
          this.loading = false
        });
      }
    },
  },
  mounted() {
    this.login.openId = localStorage.getItem("financial-openId")
  }
}
</script>
<style lang="less">
.login-frame {
  width: 400px;

  &-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 32px;
  }
}
</style>
