/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022纷析云（杭州）科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年03月08日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {get, toFormatString} from 'xe-utils'
import {Button, Colgroup, Column, Edit, Export, Filter, Icon, Input, Keyboard, Option, Pager, Pulldown, Select, Table, Toolbar, Tooltip, Validator, VXETable} from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import 'vxe-table/styles/cssvar.scss'
import {ToMoney, ToNumNumber} from "@js/common/utils";

VXETable.setup({
  size: "mini",
  table: {
    highlightHoverRow: true,
    size: 'mini',
    scrollY: {
      enabled: false
    },
    scrollX: {
      enabled: false
    },
    showOverflow: true,
    columnConfig: {
      resizable: true
    },
    rowConfig: {
      height: 40,
      isCurrent: true,
      isHover: true
    }
  },
  button: {
    size: 'medium'
  },
  i18n: (key, args) => toFormatString(get(zhCN, key), args)
})

VXETable.formats.add("toMoney", {
  cellFormatMethod: ({cellValue}) => {
    return ToMoney(cellValue)
  }
})

VXETable.formats.add("toNumNumber", {
  cellFormatMethod: ({cellValue}) => {
    return ToNumNumber(cellValue);
  }
})

export const useTable = (app) => {
  app.use(Table)
  app.use(Column)
  app.use(Pager)
  app.use(Toolbar)
  app.use(Colgroup)
  app.use(Tooltip)
  app.use(Filter)
  app.use(Icon)
  app.use(Validator)
  app.use(Keyboard)
  app.use(Edit)
  app.use(Select)
  app.use(Input)
  app.use(Export)
  app.use(Option)
  app.use(Button)
  app.use(Pulldown)
}
