/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 纷析云（杭州）技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年11月06日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {defineAsyncComponent} from "vue";


export default {
  moduleKey: "Salary",
  components: [
    {name: 'Salary', title: '薪资', component: defineAsyncComponent(() => import('@/views/salary/Salary.vue'))},
    {name: 'Emp', title: '员工信息', component: defineAsyncComponent(() => import('@/views/salary/HrTable'))},
    {name: 'SocialSecurity', title: '保险设置', component: defineAsyncComponent(() => import('@/views/salary/SocialSecurity'))},
    {name: 'SalaryDetail', title: '薪资明细', component: defineAsyncComponent(() => import('@/views/salary/SalaryDetail.vue'))},
  ],
  menu: [{
    title: '工资',
    key: 'Salary',
    icon: 'fi fi-fp',
    children: [
      {
        title: '工资信息',
        key: 'Salary',
        auth: 'salary-salarymanage-canview',
      },
      {
        title: '员工信息',
        key: 'Emp',
        auth: 'salary-employinfo-canview',
      },
      {
        title: '五险一金设置',
        key: 'SocialSecurity',
        auth: 'salary-insuranceset-canview',
      },
    ]
  }]
}
