<template>
  <div>
    <div class="text-center font-bold text-16px primary-color mb-16px">
      微信扫码，立即登录
    </div>
    <div>
      <Loading text="生成中" :loading="!qrLoaded"></Loading>
      <img v-show="!qrLoaded" src="/images/defgzh.jpg" width="258" height="258">
      <img v-show="qrLoaded" :onload="onQrLoaded" :src="`/api/wechat/login/code/${tag}`" @click="updateCode" width="258" height="258">
      <div class="text-center text-12px">二维码有效期剩余：{{ timeOut }}秒</div>
    </div>
    <div @click="$emit('changeView','LoginForm')" class="text-center primary-color  text-hover mt-16px">
      账号登录
    </div>
  </div>
</template>
<script setup>

import common from "@api/common";
import {delay} from "xe-utils";
import {onUnmounted, ref} from "vue";
import {v4 as uuidv4} from "uuid";

const emits = defineEmits('changeView');
const timeOut = ref(600)
const qrLoaded = ref(false)
const st = ref(0)
const st2 = ref(0)
const tag = ref(uuidv4())

const checkLogin = () => {
  common.checkWxLogin(tag.value).then(({success, data}) => {
    if (success) {
      if (data.openId) {
        localStorage.setItem("financial-openId", data.openId);
        emits('changeView', 'Registered')
      } else {
        window.location.replace('/');
      }
    } else {
      st2.value = delay(checkLogin, 1500);
    }
  })
}
checkLogin();

const updateCode = (e) => {
  tag.value = uuidv4();
  e.target.src = "/api/wechat/login/code/" + tag.value + "?r=" + Date.now();
  timeOut.value = 600;
}

const updateTime = () => {
  timeOut.value -= 1;
  if (timeOut.value === 0) {
    updateCode();
  }

  st.value = delay(updateTime, 1000);
}
updateTime();

const onQrLoaded = () => {
  qrLoaded.value = true;
  timeOut.value = 600;
}

onUnmounted(() => {
  clearTimeout(st2.value)
  clearTimeout(st.value)
})
</script>
