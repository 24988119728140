/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 纷析云（杭州）技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年11月06日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {defineAsyncComponent} from "vue";


export default {
  moduleKey: "Cashier",
  components: [
    {name: 'CashierAccount', title: '账户设置', component: defineAsyncComponent(() => import('@/views/cashier/CashierAccount.vue'))},
    {name: 'CashierIoType', title: '收支类别', component: defineAsyncComponent(() => import('@/views/cashier/CashierIoType.vue'))},
    {name: 'CashierJournal', title: '日记账', component: defineAsyncComponent(() => import('@/views/cashier/CashierJournal.vue'))},
    {name: 'InternalTransfer', title: '内部转账', component: defineAsyncComponent(() => import('@/views/cashier/InternalTransfer.vue'))},
    {name: 'FundsReport', title: '资金报表', component: defineAsyncComponent(() => import('@/views/cashier/FundsReport.vue'))},
    {name: 'CheckUp', title: '核对总账', component: defineAsyncComponent(() => import('@/views/cashier/CheckUp.vue'))},
  ],
  menu: [{
    title: '资金',
    key: 'Cashier',
    icon: 'fi fi-zj',
    children: [
      {
        title: '日记账',
        key: 'CashierJournal',
        auth: 'cash-cashjournal-canview',
      },
      {
        title: '内部转账',
        key: 'InternalTransfer',
        auth: 'cash-transfer-canview',
      },
      {
        title: '资金报表',
        key: 'FundsReport',
        auth: 'cash-report-canview',
      },
      {
        title: '核对总账',
        key: 'CheckUp',
        auth: 'cash-cdcheck-canview',
      },
      {
        title: '收支类别',
        key: 'CashierIoType',
        auth: 'cash-ietype-canview',
      },
      {
        title: '账户设置',
        key: 'CashierAccount',
        auth: 'cash-cdaccount-canview',
      }
    ]
  }]
}
