/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 纷析云（杭州）技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年11月06日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {defineAsyncComponent} from "vue";


export default {
  moduleKey: "Assets",
  components: [
    {name: 'Assets', title: '资产管理', component: defineAsyncComponent(() => import('@/views/assets/Assets.vue'))},
    {name: 'AssetsType', title: '资产类型', component: defineAsyncComponent(() => import('@/views/assets/AssetsType.vue'))},
    {name: 'AssetsDepreciationList', title: '折旧明细表', component: defineAsyncComponent(() => import('@/views/assets/AssetsDepreciationList.vue'))},
    {name: 'AssetsSummary', title: '资产汇总', component: defineAsyncComponent(() => import('@/views/assets/AssetsSummary.vue'))},
    {name: 'AssetsCheck', title: '资产核对总账', component: defineAsyncComponent(() => import('@/views/assets/AssetsCheck.vue'))},
  ],
  menu: [{
    title: '资产',
    key: 'Assets',
    icon: 'fi fi-gdzc',
    children: [
      {
        title: '资产管理',
        key: 'Assets',
        auth: 'fixedassets-fixedassets-card-canview',
      },
      {
        title: '资产类别',
        key: 'AssetsType',
        auth: 'fixedassets-fixedassettype-canview',
      },
      {
        title: '折旧明细表',
        key: 'AssetsDepreciationList',
        auth: 'fixedassets-depreciationledger-canview',
      },
      {
        title: '资产汇总',
        key: 'AssetsSummary',
        auth: 'fixedassets-fasummary-canview',
      },
      {
        title: '资产对账',
        key: 'AssetsCheck',
        auth: 'fixedassets-checkledger-canview',
      }
    ]
  }]
}
