<template>
  <Loading :text="stateText" :loading="paying"></Loading>
  <div class="flex pt-26px px-16px pb-16px">
    <div class="flex-1">
      <div class="font-bold text-16px mb-16px">服务订购</div>
      <div class="rounded-2xl flex h-277px w-506px" style="background-color: #EDF1FC">
        <div class="rounded-2xl p-12px flex flex-column vip-qr">
          <div class="vip-qr-tip">限时折扣</div>
          <div class="flex-1 white-color mb-12px">
            <Avatar :src=" store.state.User.avatarUrl || '/images/yun.png'" :width="50">
              <div class="leading-50px">{{ store.state.User.realName }}</div>
            </Avatar>
          </div>
          <div class="rounded-2xl bg-white-color h-245px flex flex-column p-15px">
            <div class=" flex justify-between vip-server h-120px">
              <div>
                <div>服务套餐</div>
                <div>购买时长</div>
                <div>成员人数</div>
                <div>账套数量</div>
                <div>服务支持</div>
              </div>
              <div style="color: #FF8D1A">
                <div>超级会员</div>
                <div>365天</div>
                <div>不限制</div>
                <div>不限制</div>
                <div>一对一服务</div>
              </div>
            </div>
            <div class="flex justify-between vip-server-price items-center">
              <div class="text-13px">订单金额</div>
              <div>
                联系客户咨询
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-1 flex-column items-center justify-center">
          <!--          <img :onload="qrLoaded" :onerror="qrError" :src="qrCodeSrc" alt="微信支付二维码" class="w-220px h-220px">-->
          <!--          <div class="text-center mb-16px"><i class="fa fa-wechat green-color"></i> 微信扫码支付</div>-->
          <img :src="'/api'+store.state.webConfig.qrCode" alt="微信扫一扫，加客服微信" class="w-220px h-220px">
          <div class="text-center mb-16px"><i class="fa fa-wechat green-color"></i> 微信扫一扫，加客服微信</div>
        </div>
      </div>
    </div>
    <div class="w-250px ml-11px">
      <div class="font-bold text-16px mb-16px">特权对比</div>
      <div class="rounded-2xl h-277px flex" style="background-color: #EDF1FC">
        <div class="flex-1 flex flex-column items-center text-12px py-26px">
          <div class="flex-1 leading-36px">功能特权</div>
          <div class="flex-1 leading-36px">账套数限制</div>
          <div class="flex-1 leading-36px">用户数限制</div>
          <div class="flex-1 leading-36px">电子档案空间</div>
          <div class="flex-1 leading-36px">技术支持</div>
          <div class="flex-1 leading-36px">应用中心</div>
        </div>
        <div class="flex-1 rounded-2xl bg-white-color flex flex-column items-center text-12px py-26px font-bold primary-color border border-blue-500">
          <div class="flex-1 leading-36px">超级会员</div>
          <div class="flex-1 leading-36px">不限制</div>
          <div class="flex-1 leading-36px">不限制</div>
          <div class="flex-1 leading-36px">1000M</div>
          <div class="flex-1 leading-36px">一对一</div>
          <div class="flex-1 leading-36px">VIP应用免费</div>
        </div>
        <div class="flex-1 flex flex-column items-center text-12px py-26px">
          <div class="flex-1 leading-36px">免费用户</div>
          <div class="flex-1 leading-36px">{{ store.state.User.accountSetsNum }}账套</div>
          <div class="flex-1 leading-36px">{{ store.state.User.userNum }}用户</div>
          <div class="flex-1 leading-36px">50M</div>
          <div class="flex-1 leading-36px">企业微信群</div>
          <div class="flex-1 leading-36px">按应用付费</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import {onUnmounted, ref} from "vue";
import {message} from "heyui.ext";
import setting from "@api/setting";
import {useStore} from "vuex";
import {v4 as uuidv4} from "uuid";

const store = useStore();

const t = ref(uuidv4());
const st = ref(0);
const emits = defineEmits(['success']);

const qrCodeSrc = ref(`/api/pay/qr/code?payType=WECHAT&title=升级365*VIP&t=` + t.value)

const loading = ref(false)
const paying = ref(false)
const stateText = ref('已扫码待支付')

const checkOrder = () => {
  loading.value = true;
  setting.pay.check(t.value).then(({data}) => {
    if (data) {
      message('支付成功~');
      emits('success')
    } else {
      message.warn("未检查到订单信息,请稍后再试~")
    }
  }).finally(() => loading.value = false)
}

const checkState = () => {
  setting.pay.state(t.value).then(({data}) => {
    paying.value = data !== '0';
    if (data === '2') {
      stateText.value = '已支付成功~';
      paying.value = false;
      message.success("支付成功~");
      emits('success')
    } else if (data === '3') {
      message.success("支付已经取消~");
      paying.value = false;
      t.value = uuidv4();
      qrCodeSrc.value = `/api/pay/qr/code?payType=WECHAT&title=365*VIP&t=` + t.value;
    } else {
      st.value = setTimeout(() => {
        checkState();
      }, 2000);
    }
  }).catch(() => clearTimeout(st.value)).finally(() => loading.value = false)
}

const qrLoaded = () => {
  checkState();
}

const qrError = () => {
  message.error("支付二维码请求次数过多，请稍后再试~");
  qrCodeSrc.value = '/images/defgzh.jpg'
}

onUnmounted(() => {
  clearTimeout(st.value);
})
</script>

<style scoped lang="less">
.vip-info {
  border: 1px solid #e2b169;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  padding: 28px 8px;
  background-color: #fbf4ea;
}

.vip-title {
  color: #56350e;
}

.vip-price {
  color: #e2b169;
  font-size: 38px;
  font-weight: bold;
}

.vip-qr {
  background-color: #2d7ff9;
  width: 267px;
  position: relative;
  background-image: url("@/assets/vip-bg.png");
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: 110px 31px;

  &-tip {
    position: absolute;
    top: 0;
    right: 0;
    width: 59px;
    height: 24px;
    background: linear-gradient(90deg, #E94F47 0%, #FF8D1A 100%);
    border-radius: 0px 12px 0px 12px;
    font-size: 12px;
    color: @white-color;
    text-align: center;
    line-height: 24px;
  }
}

.vip-server {
  > div {
    display: flex;
    flex-direction: column;
    font-size: 13px;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.vip-server-price {
  margin-top: 10px;
  border-top: 1px solid @gray3-color;
  padding-top: 10px;
}
</style>

