<template>
  <div class="login-frame">
    <div class="login-frame-title">
      <template v-if="reg.openId">登录并绑定微信</template>
      <template v-else>注册</template>
    </div>
    <Form class="login" ref="loginForm" :model="reg" :rules="{}" :validOnChange="true">
      <FormItem :showLabel="false" required prop="mobile" label="手机号">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-user"></i>
          </div>
          <Input v-model.trim="reg.mobile" type="text" placeholder="请输入手机号" class="h-42px"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" required prop="code" label="验证码" class="my-16px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon h-input-prefix-icon-code"></div>
          <div class="h-input-group" style="width: auto;flex: 1">
            <Input v-model.trim="reg.code" type="text" placeholder="验证码" maxlength="4"/>
            <SmsVerificationCode :mobile="reg.mobile"/>
          </div>
        </div>
      </FormItem>
      <FormItem v-if="!webConfig.wechatLogin" :showLabel="false" :required="!webConfig.wechatLogin" prop="password" label="密码" class="!pb-25px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-lock"></i>
          </div>
          <Input v-model.trim="reg.password" placeholder="请输入密码" type="password"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" class="!pb-0px">
        <Button v-if="webConfig.wechatLogin" @click="regSubmit" :loading="loading" color="primary" block>绑 定 登 录</Button>
        <Button v-else @click="regSubmit" :loading="loading" color="primary" block>立 即 注 册</Button>
        <div class="text-center my-32px">
          <span class="text-hover" @click="$emit('changeView','LoginForm')">
           <template v-if="reg.openId">已有账号密码，返回账号密码登录</template><template v-else>返回登录</template>
          </span>
        </div>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import SmsVerificationCode from "../../components/SmsVerificationCode"
import common from "@api/common";
import {mapState} from "vuex";

export default {
  name: 'Registered',
  components: {SmsVerificationCode},
  data() {
    return {
      reg: {
        mobile: "",
        code: "",
        openId: null,
        password: "",
      },
      loading: false
    };
  },
  computed: {
    ...mapState(['webConfig'])
  },
  methods: {
    regSubmit() {
      let validResult = this.$refs.loginForm.valid();
      if (validResult.result) {
        this.loading = true;
        common.register(this.reg).then(() => {
          localStorage.removeItem("financial-openId");
          window.location.replace('/');
        }).finally(() => {
          this.loading = false
        });
      }
    }
  },
  mounted() {
    this.reg.openId = localStorage.getItem("financial-openId")
  }
}
</script>
